button,
input[type='submit'] {
  @include button;
  @include appearance(none);
  background-color: none;
  color: $color-black;
  font-size: 11px;
  letter-spacing: 1px;
  border: 1px solid $color-black;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

.button {
  @include button;
  padding: 5px 27px 6px 13px;
  border: 1px solid $color-black;
  background: url('/media/images/global/arrow_right_white.png') no-repeat center right 11px $color-black;
  &:hover {
    color: $color-black;
    background: url('/media/images/global/arrow_right_black.png') no-repeat center right 11px $color-white;
  }
}

.button--wide {
  display: block;
  width: 100%;
}

@mixin button--inverted {
  @include button;
  border: 1px solid $color-black;
  color: $color-black;
  background: url('/media/images/global/arrow_right_black.png') no-repeat center right 11px $color-white;
  &:hover {
    color: $color-white;
    background: url('/media/images/global/arrow_right_white.png') no-repeat center right 11px $color-black;
  }
}

.button--inverted {
  @include button--inverted;
}

.button--secondary {
  @include button--secondary;
}

.input-button--secondary {
  @include input-button--secondary;
}

.button--underline {
  @include button;
  border: 0;
  background: none;
  border-bottom: 2px solid #000;
  color: black;
  padding-left: 0;
  padding-right: 0;
  &:hover {
    border-color: $color-black;
    background: url('/media/images/global/arrow_right_black.png') no-repeat center right 11px $color-white;
    color: $color-black;
  }
}

@mixin button--outline {
  @include button;
  border: 2px solid $color-black;
  color: $color-black;
  background: $color-white;
  &:hover {
    border-color: $color-black;
    color: $color-black;
    background: $color-white;
  }
}

.button--outline {
  @include button--outline;
}

.button--outline.button--inverted {
  border-color: $color-black;
  color: $color-black;
  &:hover {
    color: $color-black;
    border-color: $color-black;
  }
}

@mixin button--outline-thin {
  @include button--outline;
  font-size: 11px;
  border: 1px solid $color-black;
}

.button--outline-thin {
  @include button--outline-thin;
}

.button--disabled {
  background-color: $color-light-gray;
  background-image: none;
  padding-right: 13px;
  &:hover {
    background-color: $color-light-gray;
    background-image: none;
    color: $color-white;
    cursor: not-allowed;
  }
}
///
/// Links
///

a {
  color: $base-link-color;
  &:active,
  &:focus {
    outline: none;
  }
}

@mixin l1-l2-base {
  font-family: $main-font;
  font-weight: 500;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

.link {
  @include link($color-black, $color-black);
}

.link--inverted {
  color: $color-black;
  border-color: $color-black;
  &:hover {
    color: $color-black !important;
    border-color: $color-black !important;
  }
}

.link--light {
  color: $color-white;
  border-color: $color-white;
  &:hover {
    color: $color-black;
    border-color: $color-black;
  }
}

@mixin link--regular {
  text-transform: none;
  letter-spacing: normal;
  font-family: $main-font;
  font-weight: 400;
  border-bottom: 1px solid $color-black;
}

.link--regular {
  @include link--regular;
}

.link--block {
  display: block;
}

.quickshop__button,
.quickshop__button:hover {
  background: $color-black;
  color: $color-white;
}

///
/// Link overrides
/// Mostly used for touch devices, but also available for desktop
///
@mixin link--outline {
  border-style: solid;
  border-width: 2px;
  padding: 10px;
  text-decoration: none;
}

@mixin link--underline {
  border-top: none;
  border-right: none;
  border-bottom: 2px solid;
  border-left: none;
  padding: 0 0 5px 0;
}

///
/// additional link styles
/// would consolidate but previous styles are used to override
///

.l2 {
  @include l2;
}

.l7 {
  @include l7;
}

.l3 {
  @include l3;
}

.l4 {
  @include l4;
}

.l5 {
  @include l5;
}

// used in dropdown
@mixin l6 {
  font-family: $main-font;
  font-size: 14px;
  color: $color-black;
  line-height: 18px;
  text-decoration: none;
  border-bottom: 1px solid $color-black;
  &:hover {
    text-decoration: none;
  }
}

.l6 {
  @include l6;
}
