$checkout-summary-label: 65%;
$checkout-summary-value: 35%;

///
/// Order summary panel
///
$summary-space: 10px;

// reused pattern
.float-left-text-right {
  float: left;
  text-align: right;
}

.order-summary-panel {
  padding-top: 10px;
  .checkout__content & {
    margin-top: 20px;
    border-top: $cart-line;
  }
  .checkout__sidebar & {
    @include checkout-sidebar-panel;
    border-top: none;
    @include breakpoint($small-up) {
      margin: 0 10px;
    }
    @include breakpoint($medium-up) {
      background: none;
      margin: 0;
      padding: 16px 0;
    }
    .viewcart & {
      @include breakpoint($small-down) {
        margin-top: 10px;
      }
    }
  }
}

.order-summary-panel__title {
  text-align: right;
  .checkout__sidebar & {
    display: block;
    text-align: left;
  }
}

.shipmethod-panel__title {
  text-align: right;
}

// Summary items
.order-summary__content {
  margin-bottom: 0 !important; // remove generic panel style
}

.order-summary__stock_items_only {
  @include float-left-text-right;
  font-size: 0.8em;
}

// Shipping
.order-summary__shipping {
  @include float-left-text-right;
  .checkout__sidebar & {
    text-align: left;
  }
  text-transform: uppercase;
  width: $checkout-summary-label;
  form {
    .checkout__content & {
      position: relative;
      top: 0.9em;
    }
    display: inline-block;
    width: 100%;
    @include breakpoint($medium-up) {
      .checkout__content & {
        width: 200px;
      }
    }
    .form-item {
      margin: 0;
    }
  }
  // let our dropdown compress
  .selectBox-dropdown {
    overflow: hidden;
    width: 100%;
    text-transform: none;
  }
  .form-item select {
    text-transform: none;
  }
}

.order-summary__shipping-method {
  margin: 0;
  display: none;
  .checkout__sidebar & {
    display: none;
  }
  .viewcart .checkout__sidebar & {
    display: inline-block;
  }
}

.order-summary__stock_items_only {
  margin-top: 10px;
}

.viewcart .checkout__sidebar {
  #checkout_shipmethod {
    display: none;
  }
}

.ship-method {
  a.selectbox {
    min-width: 140px;
    text-align: left;
  }
  .viewcart .checkout__sidebar & {
    a.selectbox {
      font-family: $main-font;
      font-weight: normal;
      width: auto !important;
      min-width: 0;
      border: 0 !important;
      &:hover {
        cursor: default;
        color: #000;
        text-decoration: none;
      }
      .selectBox-arrow {
        display: none;
      }
    }
  }
}

//estimated delivery date wrapper
.estimated_delivery_date {
  width: 100%;
  margin-top: 10px;
}

.single_ship_method {
  line-height: 1.2em;
}

// Tax & Giftcard
.total,
.discount,
.subtotal,
.giftwrap,
.shipping,
.tax {
  &.label {
    @include float-left-text-right;
    text-transform: uppercase;
    width: $checkout-summary-label;
    .checkout__sidebar & {
      font-size: 13px;
      padding: 0.4em 0;
      text-align: left;
    }
  }
  &.value {
    @include float-left-text-right;
    width: $checkout-summary-value;
    white-space: nowrap;
    .checkout__sidebar & {
      padding: 0.4em 0;
    }
  }
}

.shipping {
  &.value {
    line-height: 60px;
    .checkout__sidebar & {
      line-height: normal;
      @include breakpoint($medium-up) {
        line-height: 40px;
      }
    }
  }
}

.tax {
  &.value {
    .checkout__sidebar & {
      line-height: 40px;
    }
  }
}

.total {
  &.label,
  &.value {
    .checkout__sidebar & {
      font-weight: bold;
      padding-bottom: 0;
    }
  }
}

// Total
@mixin order-summary-style {
  @include float-left-text-right;
  padding: $summary-space 0;
  @include breakpoint($large-up) {
    border-top: $cart-line;
    border-bottom: $cart-line;
  }
}

@mixin order-summary-span-style {
  @include inline-block;
  padding-top: $summary-space;
  border-top: $cart-line;
}

// Label
.order-summary__total-label {
  @include order-summary-style;
  clear: left;
  text-transform: uppercase;
  width: $checkout-summary-label;
  margin-top: 0.8em;

  // no line in sidebar
  .checkout__sidebar & {
    margin-top: 0;
    border: none;
    // remove padding just for sidebar
    padding: 0;
  }
}

.total-label__wrapper {
  // this places line in sidebar for proper collapse
  .checkout__sidebar & {
    @include order-summary-span-style;
    border-top-color: transparent; // to keep spacing with total 1px value
  }
}
// Value
.order-summary__total-value {
  @include order-summary-style;
  width: $checkout-summary-value;
  font-weight: bold;
  margin-top: 0.8em;

  // let span handle line in sidebar
  .checkout__sidebar & {
    margin-top: 0;
    border-top: none; // let the inner space collapse a border
    border: none;
    padding: 0;
  }
}

.total-value__wrapper {
  // this places line in sidebar for proper collapse
  .checkout__sidebar & {
    @include order-summary-span-style;
  }
}

///
/// Bottom buttons
///
.viewcart-buttons {
  @include pie-clearfix;
  text-align: right;
  clear: both;
  margin: $summary-space 0 $cart-spacing;
  position: relative;
}

.viewcart-buttons-panel {
  margin: 20px 0;
  .continue-buttons.empty_cart {
    display: none;
  }
  .checkout__sidebar & {
    display: none;
  }
  @include breakpoint($medium-up) {
    padding-left: $checkout-spacing;
    margin: 40px 0 0 0;
    text-align: right;
  }
  .or {
    display: block;
    text-align: center;
    margin: 5px 0;
    text-transform: uppercase;
    @include breakpoint($medium-up) {
      display: inline-block;
      line-height: 33px;
      margin: 0 22px; // slightly less than requested to sits inline on tablet
    }
    @include breakpoint($large-up) {
      margin: 0 38px;
    }
  }
  .continue-buttons {
    @include breakpoint($medium-up) {
      @include clearfix;
      float: right;
    }
    .paypal-smart-button {
      @include breakpoint($medium-up) {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
  /// Paypal button
  .paypal-checkout,
  .gwp-paypal-checkout {
    display: block;
    text-align: center;
    @include breakpoint($medium-up) {
      display: inline-block;
      height: 33px;
      vertical-align: middle;
    }
  }
  a.continue-checkout,
  a.gwp_continue-checkout {
    //@include button--secondary;
    width: 100%;
    @include breakpoint($medium-up) {
      display: inline-block;
      width: auto;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

#bottom-viewcart-buttons .viewcart-buttons-panel .continue-buttons.empty_cart {
  display: block;
}

a.btn.choose-samples-checkout {
  display: none;
}

/// Checkout button
.viewcart-buttons {
  a.continue-checkout {
    //@include button--secondary;
  }
  .go-shopping {
    @include button--checkout;
  }
}

.viewcart_subbuttons {
  @include breakpoint($medium-down) {
    border: medium none;
    border-bottom: 1px solid $color-gray-border-section !important;
    padding: 0;
    .continue-buttons,
    .button-update-bag {
      border: 1px solid $color-black;
      display: inline-block;
      float: left;
      padding: 5px;
      margin: 15px 8px;
      a {
        font-size: 9px;
        margin: 0px;
        padding: 0px;
      }
    }
  }
  @include breakpoint($small-down) {
    .go-shopping {
      border: 0 none;
      display: block !important;
      float: none !important;
      background: url('/media/images/global/arrow_right_white.png') no-repeat scroll right 7px center $color-black;
      font-size: 11px !important;
      margin: 0;
      padding: 5px 20px 7px 9px !important;
    }
  }
  .button-update-bag {
    float: right;
  }
  a {
    font-weight: bold;
  }
}

.go-shopping {
  width: 100%;
  @include breakpoint($medium-up) {
    width: auto;
  }
}

.signin-to-see-cart {
  margin-top: $cart-spacing;
  @include breakpoint($small-down) {
    margin-top: 10px;
  }
}

.choose-samples-and {
  display: none; //block;
  text-align: center;
  margin-bottom: $cart-spacing;
  font-weight: bold;
  @include breakpoint($medium-up) {
    display: none; //inline-block;
    margin-right: 15px; // specific request
    margin-bottom: 0;
  }
}

.continue-shopping {
  @include l7;
  float: left;
  display: block;
  font-weight: bold;
  text-align: center;
  font-size: 13px;
  letter-spacing: 0.1em;
  margin-top: 20px;
  width: 100%;
  @include breakpoint($medium-up) {
    margin-top: 5px;
    width: auto;
  }
  .top-viewcart-buttons & {
    @include hidden;
  }
}

.add-samples-message {
  display: none;
  @include breakpoint($large-up) {
    display: block;
    border-top: $cart-line;
    text-align: center;
    padding: 45px 0;
  }
}
