/// Samples panel, has a few states
/// .checkout__sidebar: when shown in sidebar
/// .samples-overlay: same markup, shown in a colorbox overlay
/// .samples-page: when on a full samples page standalone (mobile checkout)
///

$sample-page: '.samples-page';
$sample-sidebar: '.checkout__sidebar';
$sample-panel: '.samples-panel';
$samples-bottom-panel: '.samples-bottom-panel';

@mixin sample-all {
  #{$sample-sidebar} & {
    @content;
  }
  #{$sample-panel} & {
    @content;
  }
  #{$sample-page} & {
    @content;
  }
  #{$samples-bottom-panel} & {
    @content;
  }
}

#{$sample-page} {
  position: relative;
  margin: 0 $mobile-outer-gutter 30px;
  text-align: left;
  @include breakpoint($medium-up) {
    max-width: $max-width;
    margin: 53px auto 75px auto;
  }
  .loading {
    @include loading;
  }
  .samples-panel__title {
    padding-left: 0.6em;
    border-bottom-width: 2px;
    font-size: 21px;
    margin-bottom: 0;
  }
  .remaining {
    //font-size: 16px;
    //padding: 8px 0 14px 0;
    .available,
    .max {
      text-align: center;
      padding: 28px 20px 50px 20px;
      background: $color-gray-background;
      border-bottom: 1px solid $color-black;
    }
    &__header,
    &__header--alt {
      font-family: $main-font;
      font-weight: 500;
      font-size: 25px;
      line-height: 27px;
      letter-spacing: 0.14em;
      text-transform: uppercase;
    }
    &__sub-header {
      font-family: $main-font;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      margin-bottom: 2px;
    }
    &__text,
    &__text--alt,
    &__date {
      font-family: $main-font;
      font-weight: normal;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: normal;
      margin-top: 20px;
    }
    &__text--alt,
    &__header--alt {
      display: none;
    }
    &__note {
      font-family: $main-font;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: normal;
      margin-top: 20px;
    }
  }
  .samples-panel__pick4_or_pick1 {
    color: #ff4661;
    margin-top: 4px;
  }
}

// Samples Overlay
#{$sample-panel} {
  &__title {
    border-bottom-width: 2px;
    font-size: 21px;
    margin-bottom: 0;
    padding-left: 0.6em;
  }
  .sample-description {
    border-bottom: 1px solid $color-black;
    font-family: $main-font;
    margin-bottom: 0px;
    padding: 10px 20px 10px 13px;
    text-align: left;
    text-transform: none;
  }
  .remaining {
    p {
      font-family: $main-font;
      padding: 10px 20px 10px 13px;
      margin-bottom: 10px;
    }
  }
  .add-button {
    float: right;
    margin: 20px 0;
  }
}

// Samples buttons
.samples-buttons {
  @include clearfix;
  #{$sample-sidebar} & {
    // sidebar
    @include hidden;
  }
  #{$sample-page} & {
    // page
    &.top {
      @include breakpoint($medium-up) {
        padding-top: 15px;
        input[type='submit'] {
          color: $color-white;
        }
      }
    }
    &.bottom {
      padding-top: $checkout-spacing;
      display: none;
      @include breakpoint($medium-up) {
        padding-top: 40px;
        border-top: 1px solid $color-gray-dark;
        input[type='submit'] {
          color: $color-white;
        }
      }
    }
    input[type='submit'].btn {
      @include input-button--secondary;
      width: 100%;
      padding: 0.75em 1em;
      line-height: 1;
      height: auto;
      &.is_disabled {
        background: $color-gray-light;
      }
      @include breakpoint($medium-up) {
        width: auto;
        margin-left: 40px;
      }
    }
  }
  .continue-buttons {
    @include breakpoint($medium-down) {
      text-align: center;
    }
  }
  .return-link {
    display: block;
    line-height: 30px; // same as button to it's right
    padding-top: 16px;
    text-align: center;
    a {
      @include l1;
      width: 100%;
      font-size: 11px;
    }
    @include breakpoint($medium-up) {
      text-align: left;
      padding-top: 5px;
      float: right;
      width: auto;
    }
  }
  .add-button {
    #{$sample-page} & {
      @include breakpoint($medium-up) {
        float: right;
      }
    }
    #{$sample-sidebar} & {
      display: none;
    }
  }
}

// product listing
.product-list {
  #{$sample-sidebar} & {
    // sidebar
    min-height: 350px;
  }
  #{$sample-page} & {
    // page
    @include clearfix;
    margin-top: 35px;
    @include breakpoint($large-up) {
      padding-top: $checkout-spacing;
      margin-top: 0;
    }
  }
  #{$samples-bottom-panel} & {
    @include clearfix;
    padding: 20px;
  }
}

// product - note!! add css to sample-all, $sample-sidebaro or $sample-page
.product {
  @include sample-all {
    //all
    .skus label,
    .product-name {
      font-family: $main-font;
      font-size: 12px;
      font-weight: 500;
      text-align: center;
      text-transform: uppercase;
    }
    .sku.deactivate {
      .sample-select-button {
        background: $color-gray-light;
      }
    }
    .sample-select-button {
      @include button;
      width: 100%;
      @include breakpoint($medium-up) {
        display: block;
        position: absolute;
        width: 100%;
        bottom: 0;
        margin-top: 0;
      }
    }
    .sample-select-checkbox {
      @include hidden;
    }
  }
  #{$samples-bottom-panel} & {
    float: left;
    position: relative;
    width: 20%;
    padding: 0 7px;
    .product-name {
      font-size: 12px;
      text-align: center;
    }
  }

  #{$sample-page} & {
    // page
    padding-bottom: 5px;
    padding-top: $checkout-spacing;
    margin-bottom: 40px;
    position: relative;
    border-top: 1px solid $color-gray-dark;
    &:first-child {
      border-top: 0;
    }
    @include breakpoint($small-down) {
      @include clearfix;
    }
    @include breakpoint($medium-up) {
      @include min-height(390px);
      float: left;
      width: 22.6%;
      padding: 18px 0 90px 0;
      margin: 0 0 30px 3.2%;
      border-top: none;
      &:nth-of-type(1) {
        margin-left: 0;
      }
    }
  }
  #{$sample-panel} & {
    float: left;
    margin: 10px 20px;
    padding: 0 7px;
    position: relative;
    width: 40%;
  }

  #{$sample-sidebar} & {
    // sidebar
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid $color-gray;
    padding-left: 0;
    padding-right: 0;
    label {
      min-height: 24px;
    }
    &:first-child {
      margin-top: 0;
      border-top: none;
    }
    @include breakpoint($small-down) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.product-seperation {
  //inserted by js
  @include sample-all {
    display: none;
    @include breakpoint($medium-up) {
      display: block;
      height: 1px;
      line-height: 1px;
      width: 100%;
      clear: both;
      background: $color-gray-dark;
      & + .product {
        margin-left: 0;
      }
    }
  }
}

// product image
.product-img {
  #{$sample-sidebar} & {
    // sidebar
    max-width: 80px;
    margin: 0 10px 0 0;
    float: left;
    img {
      width: 100%;
    }
  }
  #{$sample-page} & {
    // page
    float: left;
    width: 40%;
    padding-right: 20px;
    margin-bottom: $checkout-spacing; // in case image is furthest down
    @include breakpoint($medium-up) {
      width: 100%;
      padding-right: 0;
      float: none;
    }
    img {
      width: 100%;
    }
  }
  #{$samples-bottom-panel} & {
    padding-left: 15px;
  }
  #{$sample-panel} & {
    padding-left: 15px;
    text-align: center;
    img {
      font-size: 10px;
    }
  }
}

.view-larger {
  #{$sample-panel} & {
    @include hidden;
  }
}

.details {
  #{$sample-sidebar} & {
    // sidebar
    //margin-top: $checkout-spacing;
    margin-top: 0;
  }
  #{$sample-page} & {
    // page
    float: left;
    width: 60%;
    text-align: left;
    line-height: 1.4;
    @include breakpoint($medium-up) {
      float: none;
      width: 100%;
    }
  }
}

.product-subhead {
  @include sample-all {
    display: none;
  }
}

.product-size {
  @include sample-all {
    //all
    margin-top: 8px;
    font-size: 12px;
  }
}

.skus label {
  @include sample-all {
    //all
    font-size: 12px;
    display: block;
    clear: both;
  }
}

.sample__shades-container {
  @include sample-all {
    width: 100%;
    @include breakpoint($medium-up) {
      display: none;
      position: absolute;
      bottom: 46px;
      left: 0;
    }
    .sample__shades {
      height: 36px;
      padding: 6px 0;
      border-top: 1px solid $color-black;
      border-bottom: 1px solid $color-black;
    }
    .swatch {
      float: left;
    }
    .shade {
      float: left;
      padding-left: 9px;
      height: 22px;
      @include breakpoint($small-down) {
        @include h8;
        line-height: 22px;
        height: 22px;
        overflow: hidden;
        text-overflow: initial;
        width: 84%;
      }
      @include breakpoint($medium-up) {
        @include h16;
        line-height: 22px;
      }
    }
  }
}

.skus {
  @include sample-all {
    // all
    margin-top: $checkout-spacing;
    &__single,
    &__multiple {
      display: none;
    }
    &__multiple {
      select.shade__select {
        width: 100%;
      }
      .selectBox.shade__select {
        padding-left: 0;
        height: 36px;
        border-top: 1px solid $color-black;
        border-bottom: 1px solid $color-black;
        .selectBox-label {
          @include h16;
          line-height: 36px;
          height: 36px;
        }
        .swatch__container {
          float: left;
          margin: 6px 6px 0 0;
        }
      }
    }
  }
  @include breakpoint($medium-up) {
    #{$sample-page} & {
      // page
      width: 100%;
      margin-top: 0;
      .form-item {
        margin-bottom: 0;
      }
    }
  }

  #{$samples-bottom-panel} & {
    position: absolute;
    left: 0px;
    margin-top: 0;
    top: 0;
  }
}

.shade__select-selectBox-dropdown-menu {
  @include h16;
  line-height: 36px;
  .swatch__container {
    float: left;
    margin: 6px 6px 0 0;
  }
}

#{$sample-page} {
  // page
  .sample-select-button {
    // .sample-select-checkbox {
    //   .label {
    //     color: $color-gray;
    //   }
    // }
    @include breakpoint($small-down) {
      margin-top: 20px;
    }
    @include l2;
    border: none;
  }
  .samples-pager,
  .prev-next {
    display: none;
  }
}

#{$samples-bottom-panel} {
  // bottom panel
  margin-top: 20px;
  border: 1px solid $color-gray-border-section;
  .sample-select-button {
    @include hidden;
  }
  .samples-panel__title {
    padding: 10px 20px;
    border-color: $color-gray-border-section;
    margin-bottom: 0;
  }
  .remaining {
    @include hidden;
  }
  .sample-description {
    @include hidden;
  }
}

#{$sample-sidebar} {
  // hide the samples panel's add and no thanks buttons when in the sidebar (not the popup)
  .add-button,
  .no-thanks {
    display: none;
  }
  .remaining,
  .remaining p {
    margin-bottom: 0;
  }
}

.samples-overlay .skus li {
  text-align: center;
}
