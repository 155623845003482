.links-panel {
  padding-top: 10px;
  @include breakpoint($small-up) {
    margin: 0 10px;
  }
  @include breakpoint($medium-up) {
    margin: 0;
  }
  .mobile_hidden {
    margin: 0 0 20px;
  }
  .link {
    display: block;
    font-weight: 500;
    letter-spacing: 0.1em;
  }
}
