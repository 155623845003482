.recommended-product-items {
  @include clearfix;
  // padding: 0 20px;
  .recommended-item {
    float: left;
    width: 22.75%;
    box-sizing: border-box;
    margin-right: 3%;
    img {
      width: 100%;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  .product_name {
    min-height: 76px; //*3 lines text
    a {
      @include l1;
      text-transform: uppercase;
      border: none;
      padding: 0;
    }
  }
  .produce_subname a {
    @include l2;
    text-transform: uppercase;
    border: none;
  }
  .formatted_price {
    border: 1px solid #000;
    border-width: 1px 0;
    padding: 0.4em 0;
    text-align: right;
    .price-metadata {
      color: $color-gray-dark;
      display: block;
      font-size: 13px;
      letter-spacing: 0.06em;
      margin-top: 3px;
      span {
        display: inline-block;
        &:first-child {
          padding-right: 5px;
        }
      }
    }
  }
  .quickshop {
    display: none;
  }
}
