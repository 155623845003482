.signin-panel__title {
  @include checkout-header-large;
}

.registration-panel {
  @include clearfix;
  @include checkout-content-panel;
  &__title {
    @include checkout-section-header;
    margin-bottom: 20px;
    @include breakpoint($small-down) {
      & span {
        display: block;
        line-height: 0;
        padding-bottom: 20px;
      }
    }
  }
  #checkout_registration {
    margin: 0 20px 20px;
    .how--we-will-use-container {
      .how-will-we-use {
        margin-bottom: 20px;
      }
    }
    .registration-form-container {
      .form-item {
        margin-bottom: 20px;
        .password {
          input {
            margin-bottom: 2px;
          }
        }
        .note {
          margin: 0 0 10px;
        }
      }
    }
    .divide {
      margin-top: 20px;
      p {
        margin: 0 0 10px;
      }
    }
    .continue-button-wrapper {
      float: right;
      margin: 20px;
    }
  }
}

///
/// Registration before checkout
/// not logged into elc, sign in block
.sign-in-panel {
  // This is terrible and should not be read by anyone. A special case of:
  // 1) User has item in cart
  // 2) User signs in
  // 3) User closes browser completely
  // 4) User returns to checkout, /checkout/index.tmpl
  // 5) We must hide the "main" sign in panel, but show the paypal signin panel
  @include clearfix;
  @include checkout-content-panel;
  border-top: 1px solid $color-gray-border-section;
  .messages {
    margin: 0 10px;
  }
  .elc-user-state-logged-in &.main:not(.finished) {
    display: none;
  }
  margin-bottom: 0;
}

// Sign in with full account, close browser, come back to index
.sign-in-paypal-panel {
  @include clearfix;
  display: none; // hide paypal panel for now
  &.active {
    display: block;
  }
}

///
/// Layout of signin panel
///
.checkout__new-account {
  float: right;
  width: 42%;
  @include breakpoint($small-down) {
    float: none;
    width: 100%;
    #checkout_signin_guest_user-submit {
      margin-bottom: 20px;
    }
  }
  @include half-checkout-item-left;
  @include breakpoint($medium-up) {
    //padding-right: $cart-spacing * 1.5;
  }
  #new-account-h {
    margin-bottom: 19px;
  }
  .form-item {
    float: left;
    margin-right: 10px;
    width: 55%;
    @include breakpoint($small-down) {
      width: 100%;
    }
  }
}

.checkout__return-user {
  width: 50%;
  @include half-checkout-item-right;
  @include breakpoint($medium-up) {
    //padding-left: $cart-spacing * 1.5;
  }
  @include breakpoint($small-down) {
    padding: 10px;
    width: 100%;
    #forgot-pw-note {
      margin-bottom: 20px;
    }
  }
  #return-account-h {
    margin-bottom: 19px;
  }
  .form-item {
    float: left;
    margin-right: 10px;
    width: 55%;
    @include breakpoint($small-down) {
      float: none;
      width: 100%;
    }
  }
  .password_field {
    float: none;
  }
  #checkout_signin-submit {
    margin-top: 20px;
    @include breakpoint($medium-up) {
      margin-top: 0;
    }
    @include breakpoint($small-down) {
      margin-bottom: 20px;
      margin-top: 0px;
    }
  }
  .l2 {
    @include breakpoint($small-down) {
      width: 100%;
    }
  }
}

.checkout__new-account,
.checkout__return-user {
  padding: 20px;
  h3 {
    border-bottom: none;
    margin: 0px;
    padding: 0px;
  }
  label {
    @include hidden;
  }
  @include breakpoint($medium-up) {
    position: relative;
    min-height: 200px;
    padding: 0 10px 50px 10px; // for button
    margin: 0;
  }
  @include breakpoint($small-down) {
    padding: 10px;
    .checkout__subtitle {
      font-weight: bold;
    }
  }
  input[type='text'],
  input[type='password'],
  input[type='tel'],
  input[type='number'] {
    display: block;
    width: 100%;
    @include breakpoint($medium-up) {
      //diff from mobile?
    }
  }
}

// Submit buttons
input[type='submit'].new-account__submit,
input[type='submit'].return-user__submit {
  @include breakpoint($medium-up) {
    position: absolute;
    left: 0;
    bottom: 0;
  }
}

input[type='submit'].new-account__submit {
  @include breakpoint($medium-up) {
    position: absolute;
    left: 20px;
    bottom: 0;
  }
}

.return-user__submit {
  left: 30px;
}

.guarantee-panel {
  padding: 0;
}

.guarantee-panel__title {
  margin: 38px 0 30px 0;
}

#new-or-returning-radios {
  @include breakpoint($small-down) {
    margin: 10px;
  }
}

.new-or-returning-radios {
  padding: 20px;
  @include breakpoint($medium-up) {
    padding: 0;
    margin: 20px 0;
  }
  .new-customer,
  .returning-customer {
    //@include inline-block;
  }
}
