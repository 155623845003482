.email-panel__title,
.shipping-panel__title {
  @include checkout-section-header;
}

.email-panel__title {
  border-top: none;
}

.checkout {
  .email-panel {
    line-height: 52px;
  }
}

.shipmethod-panel {
  .checkout__content & {
    margin: 20px 0;
    .ship-method-group-label {
      margin: 0 0 10px;
    }
  }
  .checkout__sidebar & {
    @include breakpoint($small-up) {
      margin: 10px 10px 25px;
    }
    @include breakpoint($medium-up) {
      margin: 10px 0 25px;
    }
    .ship-method-group-label {
      margin: 10px 0;
    }
  }
}

.shipping-panel {
  @include clearfix;
  @include checkout-content-panel;
  .messages {
    margin: 0 $checkout-spacing;
  }
  .update-address-note {
    margin-bottom: 16px;
  }
  .address_section {
    .right {
      margin: 10px 0;
    }
    .ship_title {
      font-weight: bold;
      margin-bottom: 10px;
    }
  }
  a#panel--shipping-edit {
    vertical-align: middle;
    text-indent: 0px;
  }
}

.checkout section.panel.first-panel {
  padding-top: 0;
  header h2 {
    border-top-width: 0;
  }
}

.shipping-edit-content {
  padding-bottom: $checkout-spacing;
  #checkout_shipping_panel {
    margin: 0 $checkout-spacing;
    @include breakpoint($small-down) {
      .right {
        margin: 20px 0;
        p {
          margin: 0;
        }
      }
      .ship_title {
        border-bottom: 1px solid #000000;
        font-size: 13px;
        letter-spacing: 0.2em;
        line-height: 18px;
        padding-bottom: 17px;
        text-indent: 0;
        text-transform: uppercase;
      }
      .gift-options {
        .giftwrap__is-gift {
          margin-bottom: $checkout-spacing;
        }
        .sub-section {
          .giftwrap,
          p {
            margin-bottom: $checkout-spacing;
          }
          .qas-submit-container {
            margin: 0 0 10px;
          }
        }
      }
      .sub-section {
        .qas-submit-container {
          margin: 0 0 10px;
        }
      }
      .email-sms-promotions {
        .section-email-promotions {
          margin-bottom: $checkout-spacing;
        }
        p {
          margin: 0 0 15px;
        }
      }
    }
    @include breakpoint($medium-up) {
      .address-to-use {
        .address-to-use-option {
          margin-right: 20px;
        }
      }
      .sub-section {
        .qas-submit-container {
          margin: 0 0 10px;
        }
        .new-address {
          .nojs_hidden {
            margin-bottom: 20px;
            .qas-submit-container {
              margin-top: 20px;
            }
          }
        }
        .local-collection {
          .set-collection-point {
            .form-item {
              margin: 10px 0 20px;
            }
          }
        }
      }
      .email-sms-promotions {
        .newsletter-info__text--provid,
        p {
          margin: 0 0 10px;
        }
      }
    }
  }
  .continue-button-wrapper {
    margin-top: 20px;
    @include clearfix;
    input.form-submit {
      float: right;
    }
  }
  .form-container {
    .address-to-use,
    .title {
      margin-bottom: $checkout-spacing;
      @include breakpoint($small-down) {
        margin-top: 18px;
      }
    }
    .address-to-use-option {
      @include breakpoint($small-down) {
        display: block;
      }
    }
    .first-name {
      width: 49%;
      float: left;
      margin-right: 2%;
    }
    .last-name {
      width: 49%;
      float: left;
    }
  }
}

.shipping-edit-address-content__title {
  display: none;
}

.shipping-edit-address-content__content {
  @include checkout-address;
}

///
/// Gift Options
///

.gift-options__content {
  @include pie-clearfix;
  margin: $checkout-spacing 0 30px 0;
  @include breakpoint($small-down) {
    margin: $checkout-spacing 0;
  }
}

.gift-options__title {
  margin-bottom: 18px;
}

.gift-message__title {
  margin-top: 30px;
}

.gift-message-h__title {
  display: none;
}

.gift-options-display {
  p {
    margin-bottom: 0;
  }
}

.gift-options {
  @include breakpoint($medium-up) {
    .card-message-to {
      width: 46%;
      margin-right: 8%;
      float: left;
    }
    .card-message-from {
      width: 46%;
      float: left;
    }
    .sub-section {
      .gift-msg__title {
        font-weight: bold;
        margin: 10px 0;
      }
      p {
        margin-bottom: 10px;
      }
    }
  }
}

.giftwrap__is-gift {
  display: inline-block;
}

.card-message {
  margin-bottom: 10px;
}

.card-message-to,
.card-message-from {
}

.card-message-to {
}

.card-message-from {
}

///
/// View after submissions
///

.shipping-address-display__title,
.gift-options-display__title {
  margin-bottom: 18px;
}

.shipping-address-display,
.gift-options-display {
  @include breakpoint($medium-up) {
    width: 43.3%;
  }
}

.shipping-address-display {
  border-right: 1px solid $color-gray-border-section;
  margin-bottom: 20px !important;
  @include breakpoint($medium-up) {
    float: left;
    margin: $checkout-spacing 0 0 $checkout-spacing;
  }
  @include breakpoint($small-down) {
    border-right: none;
    margin-left: $checkout-spacing;
    p {
      margin: 0;
    }
  }
  &__title {
    border-bottom: none;
    margin-bottom: 0px !important;
    @include breakpoint($small-down) {
      font-weight: bold;
      margin-top: $checkout-spacing;
    }
  }
}

.gift-options-display {
  margin: 0 $checkout-spacing $checkout-spacing $checkout-spacing;
  @include breakpoint($medium-up) {
    float: right;
    margin: $checkout-spacing $checkout-spacing $checkout-spacing 0;
  }
  &__title {
    border-bottom: none;
    margin-bottom: 0px !important;
    @include breakpoint($small-down) {
      font-weight: bold;
      margin-top: 0;
    }
  }
}

.shipping-panel__title {
  .checkout__sidebar & {
    border-left: 1px solid $color-gray-border-section;
    border-right: 1px solid $color-gray-border-section;
  }
}

.shipping-progress-panel {
  a.edit {
    position: absolute;
    right: 20px;
    text-transform: uppercase;
    top: 15px;
  }
}

.index {
  #viewcart-panel {
    @include breakpoint($small-down) {
      display: block !important;
      margin: 0 20px;
      #shopping-bag-title-edit {
        font-weight: 700;
      }
      .cart-items {
        margin: 10px 0;
        .cart-item {
          border-top: none;
          margin-bottom: 20px;
          padding-top: 10px;
          .cart-item__total {
            margin-top: 19%;
            padding-left: 25px;
          }
        }
      }
    }
  }
}
