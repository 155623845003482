/// Recommended products panel
// based on 4 up
.recommended-products-panel {
  @include mobile_hidden; // hide mobile
  clear: both;
  .checkout__panel-title {
    text-align: center;
    border: 0;
    &:before,
    &:after {
      border-top: 2px solid #000;
      width: 57px;
      content: '\a0 ';
      position: relative;
      top: 0.8em;
      display: inline-block;
      margin: 0 0.4em 0 0.2em;
    }
  }
  @include breakpoint($medium-up) {
    margin-top: 1.4em;
  }
}

.recommended-products__list {
  @include clearfix;
  // recommended items
  > li {
    float: left;
    width: 33%;
    text-align: center;
    position: relative;
    // center everything
    > a,
    > div {
      display: block;
      width: 140px;
      margin: 0 auto;
    }

    // thumbs
    .thumb {
      margin-bottom: 15px;
    }
    img {
      width: 100%;
    }
    .product_name {
      margin-bottom: 10px;
    }
    .product_subname {
      line-height: 1.3;
    }
    [type='submit'] {
      //@include button2;
      margin: 15px 0;
    }
    .quickshop {
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -70px;
    }
  }
}

.recommended-item__button {
  @include button--checkout;
  margin: 15px 0;
}

.recommended-products__list > li > .sku-brief-editorial {
  padding-bottom: 90px;
  position: relative;
}

.sku-brief-checkout__controls {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.sku-brief-editorial__reviews {
  width: 60px; // natual width of image contained
  margin: 0 auto 10px;
}
