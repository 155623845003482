#need-help-panel {
  margin-bottom: 1em;
  h3 {
    margin-bottom: 1em;
  }
  h4 {
    font-weight: bold;
    span.text {
      font-weight: normal;
    }
  }
  .phone {
    padding: 5px 0;
  }
  .chat {
    padding: 5px 0;
  }
  .email {
    padding: 5px 0;
  }
}

body#index #need-help-panel {
  display: none;
}
