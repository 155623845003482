.payment-panel__title {
  @include checkout-section-header;
}

.payment-panel {
  @include clearfix;
  @include checkout-content-panel;
  .messages {
    margin: 0 $checkout-spacing;
  }
  .payment-edit-content {
    position: relative;
    margin: 18px $checkout-spacing $checkout-spacing $checkout-spacing;
    &__title {
      @include hidden;
      //margin-bottom: 18px;
    }
    .field-are-required {
      @include hidden;
    }
    .security-info {
      position: absolute;
      top: 0;
      right: 0;
      @include breakpoint($medium-up) {
        top: 20px;
      }
      .overlay-link {
        display: block;
      }
    }
  }
  .new-address {
    .address-form {
      padding-top: 5px;
      .city-state-zip,
      .phone-1 {
        padding-top: 5px;
      }
    }
  }
  .choose-payment-method,
  .payment-information {
    display: none;
  }

  // The credit card and paypal radios
  .payment-type {
    margin-bottom: 18px;
  }
  .payment-option,
  .payment-control {
    @include clearfix;
    display: block;
    width: 100%;
    margin-bottom: 18px;
    &.radio {
      margin-bottom: 0;
      float: left;
      @include breakpoint($medium-up) {
        float: none;
      }
    }
    &.radio:first-child {
      @include breakpoint($medium-up) {
        width: 60%;
      }
    }
    &.radio:last-child {
      @include breakpoint($medium-up) {
        width: 40%;
      }
    }
    @include breakpoint($medium-up) {
      @include inline-block;
      &.radio:first-child {
        width: 53.6%;
      }
      &.radio:last-child {
        width: 45%;
      }
    }
    // input,
    label {
      text-transform: uppercase;
    }
    .related-media {
      display: none;
      @include breakpoint($medium-up) {
        display: block;
        margin-top: $checkout-spacing;
      }
      img {
        margin-right: 10px;
        display: inline-block;
      }
    }
  }
  .payment_method-container {
    margin-bottom: 18px;
  }
  .saved-payments {
    margin: 18px 0;
    .cc-info {
      margin-top: 18px;
    }
  }
  .payment-form {
    width: 100%;
    margin: 0;
    .card-number {
      @include breakpoint($medium-up) {
        width: 253px;
      }
    }
    .expires-month {
      @include breakpoint($medium-up) {
        float: left;
      }
    }
    .security-code {
      display: none;
    }
    .expires-date__container {
      margin-bottom: 18px;
      @include breakpoint($medium-up) {
        @include clearfix;
        margin-bottom: 0;
        width: 253px; //hard value for selectbox
        label {
          line-height: 19px;
        }
      }
      .expires-date__month {
        margin-bottom: $checkout-spacing;
        @include breakpoint($medium-up) {
          margin-bottom: 0;
          width: 106px;
          float: left;
        }
        .expiration_month {
          @include main-font;
        }
        select {
          min-width: 0;
        }
      }
      .expires-date__year {
        @include breakpoint($medium-up) {
          width: 106px;
          float: left;
          margin: 0 0 0 41px;
        }
        .expiration_year {
          @include main-font;
        }
        select {
          min-width: 0;
        }
      }
    }
    .cvv {
      @include breakpoint($medium-up) {
        float: left;
        width: 106px;
        margin-left: 40px;
      }
    }
  }
  .save-options {
    clear: left;
  }
  .payment-container {
    .checkout__subtitle {
      margin-bottom: 18px;
    }
    .address-to-use__container {
      margin-bottom: 18px;
      .address-to-use.radio {
        display: block;
        @include breakpoint($medium-up) {
          @include inline-block;
          &:first-child {
            width: 53.6%;
          }
          &:last-child {
            width: 45%;
          }
        }
      }
      label {
        text-transform: uppercase;
      }
    }
  }
  .new-address {
    @include checkout-address;
    &.add {
      margin-bottom: 10px;
    }
  }
  .existing-address {
    //display: none;
    margin-bottom: $checkout-spacing;
    .select-menu {
      margin: 20px 0;
    }
  }
  .select-address {
    margin-bottom: 20px;
    .address-to-use {
      @include breakpoint($small-down) {
        display: block;
      }
      @include breakpoint($medium-up) {
        &.radio {
          margin-right: 10px;
        }
      }
    }
  }
  .shipping-address {
    margin-bottom: 30px;
  }

  ///
  ///  Giftcards
  ///
  .payment-giftcard {
    margin-bottom: $checkout-spacing;
  }
  .has-giftcard {
    margin-bottom: $checkout-spacing;
  }
  .checkout_giftcard {
    label {
      display: none;
    }
    @include breakpoint($medium-up) {
      .giftcard__number {
        float: left;
        width: 46.3%;
      }
      .giftcard__pin {
        float: right;
        width: 46.3%;
      }
    }
  }
  // .payment-giftcard__number {
  //   @include third-checkout-item-left;
  // }
  // .payment-giftcard__pin {
  //   @include third-checkout-item-middle;
  // }
  // .payment-giftcard__check-balance {
  //   @include third-checkout-item-right;
  // }
}

///
/// View after submissions
///

.payment-display {
  .checkout__subtitle {
    margin-bottom: 18px;
  }
  .payment,
  .address {
    @include breakpoint($medium-up) {
      width: 43.3%;
      &.payment--paypal {
        width: 100%;
        margin: 0;
        padding: $checkout-spacing $checkout-spacing 30px $checkout-spacing;
      }
    }
  }
  .address {
    margin: 18px $checkout-spacing 30px $checkout-spacing;
    @include breakpoint($medium-up) {
      float: left;
      margin: $checkout-spacing 0 30px $checkout-spacing;
    }
  }
  .payment {
    margin: 0 $checkout-spacing 30px $checkout-spacing;
    @include breakpoint($medium-up) {
      float: right;
      margin: $checkout-spacing $checkout-spacing 30px 0;
      &.payment--paypal {
        float: none;
      }
    }
  }
}
