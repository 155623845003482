@mixin checkout-sidebar-panel {
  background: $color-gray-background;
  padding: 16px 10px;
}

@mixin checkout-content-panel {
  border-left: 1px solid $color-gray-border-section;
  border-right: 1px solid $color-gray-border-section;
  @include breakpoint($small-down) {
    border-left: medium none;
    border-right: medium none;
    margin: 0 5px;
    h2 {
      font-weight: bold;
    }
  }
}

// Reusable extendables
@mixin checkout-header {
  @include h24;
  text-indent: 0;
  margin: 4px 0 10px 0;
  padding-bottom: 17px;
  border-bottom: 1px solid $color-black;
}

@mixin checkout-header-large {
  @include h19;
  text-indent: $checkout-spacing;
  margin: 4px 0 0 0;
  padding-bottom: 16px;
  border-bottom: 2px solid $color-black;
}

@mixin checkout-item-column {
  width: 100%;
  clear: both;
  // keep 100% until after ipad portrait
  @include breakpoint($medium-up) {
    float: left;
    clear: none;
  }
}

@mixin half-checkout-item-left {
  @include checkout-item-column;
  clear: left;
  @include breakpoint($medium-up) {
    width: 50%;
    padding: 0 $cart-spacing;
  }
}

@mixin half-checkout-item-right {
  @include checkout-item-column;
  @include breakpoint($medium-up) {
    width: 50%;
    padding-left: 0 $cart-spacing;
  }
}

@mixin checkout-below-column {
  border-top: $cart-line;
  clear: both;
}

@mixin checkout-address {
  .ship-type {
    display: none;
  }
  .name-fields,
  .addresses,
  .city-state-zip,
  .country,
  .phones {
    label {
      display: none;
    }
  }
  margin-top: 18px;
  .address-to-use {
    margin-bottom: 20px;
    .radio {
      display: block;
      @include breakpoint($medium-up) {
        display: inline;
        margin-right: 10px;
      }
    }
  }
  .form-item {
    &.state,
    &.select-menu {
      margin-bottom: 20px;
    }
    &.phone-2,
    &.address3 {
      @include hidden;
    }
    @include breakpoint($medium-up) {
      &.first-name {
        float: left;
        width: 48%;
      }
      &.last-name {
        float: right;
        width: 48%;
      }
      &.address1 {
        clear: both;
      }
      &.address2 {
        width: 100%;
      }
      &.city {
        float: left;
        margin-left: 2.33%;
        width: 38%;
      }
      &.state {
        float: right;
        width: 38.44%;
      }
      &.postal-code {
        float: left;
        width: 19.44%;
      }
      &.phone-1 {
        float: left;
        width: 48%;
      }
    }
  }
  .city-state-zip {
    @include breakpoint($medium-up) {
      @include clearfix;
    }
  }
  .country {
    margin-bottom: 20px;
    &__note {
      margin-top: 4px;
      font-size: 11px;
      line-height: normal;
    }
    @include breakpoint($medium-up) {
      width: 48%;
    }
  }
  .phone-required {
    @include hidden;
  }
  .phones {
    @include clearfix;
  }
}

@mixin loading {
  @include background(image-url('ajax-loading.gif') no-repeat center center $color-white);
  @include opacity(0);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  &.fade-in {
    @include opacity(0.8);
  }
  .text {
    display: none;
  }
}

// Checkout common styles
.checkout {
  @include clearfix;
  max-width: $max-width;
  position: relative;
  z-index: 1;
  margin-bottom: 2em;
  &.samples,
  &.viewcart,
  &.checkout-confirmation-page {
    @include breakpoint($small-down) {
      margin-left: $mobile-outer-gutter;
      margin-right: $mobile-outer-gutter;
    }
  }
  @include breakpoint($medium-up) {
    margin: 0 auto 2em auto;
  }
  @if $cr19 == false {
    @include breakpoint($medium-up) {
      margin: 0 auto 2em auto;
    }
  }
  @if $cr19 == true {
    @include breakpoint($large-up) {
      margin: calc(#{$mobile-nav-height} + #{$mobile-top-offer-height}) auto 2em auto;
    }
  }
  header {
    position: relative;
  }
  .checkout__panel-title-container {
    text-align: center;
    @include breakpoint($medium-up) {
      text-align: left;
    }
  }
  .continue-button-wrapper {
    @include breakpoint($small-down) {
      .btn {
        width: 100%;
      }
    }
  }
  .messages {
    margin: 0;
    background: none;
    border: none;
    padding: 0; // allows us to collapse when not full
    .single-message:first-child {
      margin-top: 20px;
    }
    .single-message {
      margin-bottom: 10px;
    }
    .close_link {
      margin-right: 10px;
      display: none;
    }
  }
  input[type='text'],
  input[type='password'],
  input[type='tel'],
  input[type='number'] {
    @include input-placeholder {
      text-transform: uppercase;
    }
    margin-bottom: $checkout-spacing;
  }
  //selectbox
  .shipping-panel,
  .payment-panel {
    .selectBox-label {
      color: $color-gray-dark;
    }
  }
} //.checkout

///
/// Main content area
///
.checkout__content {
  @include breakpoint($small-up) {
    .guarantee-panel {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
  @include breakpoint($medium-up) {
    width: 100% - $checkout-sidebar-width;
    float: left;
    margin: $checkout-pushdown 0;
    padding: 0 30px 0 0;

    // Cart is smaller sidebar
    .viewcart & {
      width: 100% - $cart-sidebar-width; // override for cart page
    }
  }
}

///
/// Sidebar specific
///
.checkout__sidebar {
  padding-bottom: $cart-spacing; // a little space at the end

  // Handle true 100% height gray bar
  @include breakpoint($medium-up) {
    width: $checkout-sidebar-width; // default is checkout
    float: left;
    margin: 31px 0;
    // Cart is smaller sidebar
    .viewcart & {
      width: $cart-sidebar-width; // override for cart page
      &:after {
        width: $cart-sidebar-width; // override for cart page
        left: 100% - $cart-sidebar-width;
      }
    }
  }
}

.below-columns {
  @include breakpoint($medium-up) {
    border-top: $cart-line;
    clear: both;
  }
}

///
///  Bredcrumb at top of page
///
#mobile-breadcrumb {
  font-size: 13px;
  margin: 0px 10px 10px 15px;
  li {
    display: inline-block;
  }
  @include breakpoint($small-down) {
    font-size: 10px;
  }
}

///
/// Panel titles
///
.checkout__panel-title {
  @include breakpoint($medium-down) {
    font-weight: bold;
    letter-spacing: 0.1em;
  }
  @include checkout-header;
  .collapsed & {
  }
  .checkout__content & {
  }
  .checkout__sidebar & {
  }
}

.checkout__subtitle {
  @include checkout-header;
}

@mixin checkout__indent {
  padding-left: $checkout-spacing;
}

.checkout__indent {
  @include checkout__indent;
}

@mixin checkout__gutter {
  @include breakpoint($medium-up) {
    margin: 0 $checkout-spacing;
  }
}

.checkout__gutter {
  @include checkout__gutter;
}

.panel:before,
.panel header:before,
.row:before,
.product:before,
.column:before,
.panel:after,
.panel header:after,
.row:after,
.product:after,
.columnn:after {
  display: table;
  line-height: 0;
  content: '';
}

///
/// Checkout buttons
///
.checkout {
  section.panel {
    position: relative;
  }
  a.btn {
    @include button;
    &.edit {
      background: $color-black url('/media/images/global/arrow_right_white.png') no-repeat scroll right 7px center;
      border: 1px solid $color-black;
      position: absolute;
      top: 8px;
      right: 20px;
      &:hover {
        background: $color-white url('/media/images/global/arrow_right_black.png') no-repeat scroll right 7px center;
        color: $color-black;
      }
    }
  }
  input[type='submit'].btn {
    @include input-button--secondary;
    @include breakpoint($small-down) {
      width: 100%;
      padding: 0.75em 1em;
      line-height: 1;
      height: auto;
    }
  }
  .loading {
    @include loading;
  }
}
