.checkout-confirmation-page {
  max-width: $max-width-pages;
  @include breakpoint($small-down) {
    margin-left: 10px;
    margin-right: 10px;
  }
  .banner_top {
    background-image: url('/media/export/cms/gorgeous_skin_logo.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 257px;
    margin-top: 30px;
    width: auto;
  }
  p {
    margin-bottom: 20px;
  }
  .checkout {
    &__content {
      padding: 0px;
      width: 100%;
    }
  }
  .confirmation-panel {
    font-size: 12px;
    padding-bottom: 40px;
    @include breakpoint($small-down) {
      padding-bottom: 25px;
    }
    p {
      margin-bottom: 0;
    }
    &__title,
    .social-login__title {
      @include checkout-section-header;
      margin-bottom: 18px;
      @include breakpoint($small-down) {
        font-size: 16px;
      }
    }
    &__subtitle {
      border-bottom: none;
      font-family: $main-font;
      font-size: 12px;
      text-transform: none;
      @include breakpoint($small-down) {
        padding-bottom: 10px;
      }
    }
    &__content {
      padding-left: 0px;
      @include breakpoint($medium-up) {
        padding-left: 0px;
        width: 961px;
        margin: 0 auto;
      }
    }
    &__email-coming {
      margin-top: 36px;
      @include breakpoint($small-down) {
        margin-top: 20px;
        text-align: justify;
      }
    }
    &__actions {
      margin: 34px 0 0 0;
      @include breakpoint($medium-up) {
        margin: 34px 0 0 20px;
      }
    }
    &__my-account {
      a {
        background: url('/media/images/global/arrow_right_black.png') no-repeat scroll right 7px center;
        color: #000;
        font-weight: bold;
        margin-top: 20px;
        text-align: left;
        text-transform: uppercase;
      }
    }
  }
  .registration-panel {
    border: medium none;
    font-size: 12px;
    margin: 0 auto;
    padding: 20px 0;
    width: 961px;
    &__title {
      @include checkout-section-header;
      margin-bottom: 18px;
      @include breakpoint($small-down) {
        text-indent: 0;
        padding: 20px;
        line-height: normal;
      }
    }
    .checkout__panel-content {
      @include breakpoint($medium-up) {
        padding-left: 20px;
      }
    }
    .how-we-will-use-container {
      .required {
        @include hidden;
      }
      @include breakpoint($medium-up) {
        float: right;
        width: 43%;
        margin-right: 20px;
      }
    }
    .registration-form-container {
      @include breakpoint($medium-up) {
        float: left;
        width: 43%;
      }
    }
    .checkout-registration__email-address-title {
      @include checkout-header;
      display: block;
    }
    .checkout-registration__email-address {
      margin-bottom: 15px;
      br {
        @include hidden;
      } //oh 1page...
    }
    .checkout-registration__password {
      .label {
        @include hidden;
      }
    }
    .accepted-privacy-policy {
      margin-top: 20px;
      .terms_disclaimer {
        @include hidden; //no rb key setup
      }
    }
    .required_mark {
      @include hidden;
    }
    .continue-button-wrapper {
      clear: both;
      padding-top: 38px;
    }
  }
  .sign-in-confirmation-panel {
    font-size: 12px;
    @include clearfix;
    padding-bottom: 38px;
    &__title {
      @include checkout-header;
      margin-bottom: 18px;
      @include breakpoint($small-down) {
        font-weight: bold;
        line-height: normal;
        margin-bottom: 0;
        padding: 10px 0;
        text-indent: 0;
      }
    }
    &__content {
      @include breakpoint($medium-up) {
        float: left;
        margin-left: 20px;
      }
      .label {
        @include checkout-header;
        display: block;
      }
      .email-address {
        margin-bottom: 15px;
      }
      .password {
        .label {
          @include hidden;
          @include breakpoint($medium-up) {
            display: block;
            font-size: 1em;
            height: 3em;
            line-height: 3em;
            margin-top: 3em;
            margin-top: calc(-3em - 1px);
            margin-bottom: 1em;
            cursor: text;
          }
        }
        @include breakpoint($medium-up) {
          width: 43%;
        }
        @include breakpoint($small-down) {
          input {
            border-radius: 5px;
          }
        }
      }
      @include breakpoint($small-down) {
        #forgot-pw-note {
          margin-top: 15px;
        }
      }
    }
  }
  .guarantee-panel {
    display: block;
    clear: both;
    padding: 0;
    @include breakpoint($medium-up) {
      padding: 0 0 0 20px;
    }
    &__title {
      @include checkout-header;
      border-bottom: none;
    }
    .checkout__panel-content {
      margin-top: 32px;
    }
  }
  .checkout {
    &__sidebar {
      display: none;
    }
  }
}

.elc-user-state-anonymous {
  .checkout-confirmation-page {
    .checkout {
      &__content {
        padding: 0 30px 0 0;
        width: 73.3398%;
        @include breakpoint($small-range1) {
          width: auto;
          padding: 0;
        }
        .confirmation-panel {
          padding: 0 20px;
        }
      }
      &__sidebar {
        display: block;
        .links-panel {
          padding-top: 30px;
        }
      }
    }
    .confirmation-panel__content {
      width: auto;
    }
    .registration-panel {
      padding-top: 30px;
      width: auto;
      &__title {
        border-top: 1px solid #999999;
      }
      .content {
        padding: 0 20px;
        .registration-form-container {
          float: none;
        }
        .promotions-container {
          padding-bottom: 20px;
        }
      }
    }
    .sign-in-confirmation-panel {
      padding-top: 30px;
      &__title {
        border-top: 1px solid #999999;
        line-height: 50px;
        padding-bottom: 0px;
        text-indent: 20px;
      }
      &__content {
        margin-left: 0px;
        padding: 0 20px;
      }
    }
  }
}
