// viewcart-panel shows up on viewcart.tmpl and in the sidbar on index.tmpl

/// Mixins, Placeholders and Variables
@mixin checkout-sidebar-panel-border {
  margin-top: 6px;
  border: 5px solid $color-gray-light-alt;
  padding: 15px 15px 20px 15px;
  background: $color-white;
  &__title {
    margin-top: 34px;
  }
}

.viewcart-panel {
  //sidebar
  .checkout__sidebar & {
    @include checkout-sidebar-panel;
    position: relative;
    padding: 10px 1px !important;
    .cart-items {
      margin: 10px;
      .cart-item {
        &__desc {
          height: auto;
          width: 100%;
        }
        &__size {
          color: $color-gray;
          font-size: 12px;
        }
        &__qty,
        .symbol {
          float: left;
          font-weight: bold;
          padding: 0px;
          text-align: left;
          width: 32%;
        }
        &__total {
          float: left;
          font-weight: bold;
          margin-top: 29%;
          padding-left: 15px;
          text-align: left;
          width: 50%;
        }
        .symbol {
          color: $color-gray;
          margin-top: 7px;
        }
      }
    }
    .subtotal_panel {
      border-top: 1px solid $color-gray;
      margin: 10px;
      font-weight: bold;
    }
  }
  .checkout__content & {
    .cart-items {
      .cart-item {
        padding: 10px 0 40px;
        &__desc-container {
          @include breakpoint($small-down) {
            position: relative;
          }
        }
        &__desc {
          @include breakpoint($small-down) {
            .sub_line {
              display: none;
            }
          }
        }
        &__size {
          font-size: 13px;
          color: $color-black;
          display: block;
          @include breakpoint($landscape-up) {
            font-size: 14px;
          }
        }
        &__qty {
          @include breakpoint($small-down) {
            margin-top: 40px;
          }
        }
        &__total {
          @include breakpoint($small-down) {
            font-weight: 700;
            position: absolute;
          }
        }
        &__remove_column {
          @include breakpoint($small-down) {
            padding: 35px 20px 10px;
          }
        }
      }
    }
  }
  .top.checkout__sidebar & {
    border-top: none;
  }
  #add-samples-message {
    @include hidden;
  }
  &__title {
    font-size: 21px;
    //content
    .checkout__content & {
      @include breakpoint($medium-up) {
        border-bottom: none;
        padding-bottom: $checkout-spacing;
        margin: 0;
      }
    }
    //sidebar
    .checkout__sidebar & {
      margin: 0;
      #shopping-bag-title-edit {
        @include h24;
        @include link-reset;
        display: block;
        text-indent: 0;
      }
    }
  } // /title
  &__content {
    border: $cart-line;
    @include breakpoint($medium-down) {
      border: medium none;
      .cart-item:last-child {
        border-bottom: 1px solid $color-gray-border-section;
      }
    }
  }
}

.checkout {
  #viewcart-panel {
    background: none repeat scroll 0 0 transparent;
  }
  @include breakpoint($medium-down) {
    &__content {
      #samples-left-pane {
        display: none;
      }
    }
  }
  #samples-left-pane {
    display: none;
  }
}

.checkout.viewcart {
  @include breakpoint($medium-down) {
    margin-left: 10px;
    margin-right: 10px;
  }
  .single-message {
    color: $color-error-msg;
    &:first-child {
      padding-top: 20px;
    }
    a {
      color: $color-error-msg;
    }
    &[id^='error_offer_criteria_met'] {
      color: $color-success-msg;
      a {
        color: $color-success-msg;
      }
    }
    &.threshold-messages {
      color: $color-alert-msg;
      a {
        color: $color-alert-msg;
      }
    }
  }
  .checkout__sidebar {
    // tabbing system (pulled over from checkout.css, on BrZ UK1)
    .tab-switcher {
      @include checkout-sidebar-panel-border;
      padding: 0;
      @include breakpoint($small-down) {
        display: none;
      }
    }
    .tab-bar {
      @include clearfix;
      @include checkout-sidebar-panel;
      padding: 0;
      li {
        @include clearfix;
        display: block;
        float: left;
        background-color: $color-gray-light-alt;
      }
    }
    .tab {
      display: block;
      width: 100%;
      text-decoration: none;
      color: $color-black;
      @include breakpoint($medium-up) {
        padding: 10px 5px;
        @include h22;
      }
      @include breakpoint($large-up) {
        @include h12;
        padding: 10px;
      }
      &.active {
        background-color: white;
      }
    }
    .tab-content {
      padding: 15px;
    }
    .tab-pane {
      opacity: 0;
      height: 0;
      overflow: hidden;
      -webkit-transition: opacity 1s ease;
      -moz-transition: opacity 1s ease;
      -o-transition: opacity 1s ease;
      -ms-transition: opacity 1s ease;
      transition: opacity 1s ease;
      &.active {
        display: block;
        opacity: 1;
        height: auto;
        footer {
          display: block;
          text-align: right;
        }
      }
      header,
      footer {
        display: none;
      }
    }
    // end tabbing system
  }
}

.viewcart-header {
  border-bottom: medium none !important;
  position: relative;
  @include breakpoint($medium-down) {
    border-bottom: 1px solid $color-gray-border-section !important;
  }

  .viewcart-panel {
    &__title {
      border-bottom: none !important;
      display: inline-block;
      width: 52%;
    }
  }
  .item-count {
    display: inline-block !important;
    text-align: left;
  }
  .checkout__sidebar & {
    position: relative;
    text-align: left;
    border-bottom: $cart-line;
    #shopping-bag-edit {
      bottom: 8px;
      right: 0;
    }
    .edit_button {
      a:before {
        -moz-border-bottom-colors: none;
        -moz-border-left-colors: none;
        -moz-border-right-colors: none;
        -moz-border-top-colors: none;
        border-color: transparent $color-black transparent transparent;
        border-image: none;
        border-style: solid;
        border-width: 4px 6px 4px 4px;
        content: '';
        left: 0;
        position: absolute;
        top: 9px;
      }
      a {
        background-color: transparent;
        border: 1px solid $color-black;
        color: $color-black;
        float: right;
        padding: 5px 5px 5px 18px;
        position: relative;
        text-decoration: none;
        text-transform: uppercase;
        top: -35px;
        &:hover {
          background: $color-black url('/media/images/global/arrow_left_white.png') no-repeat scroll left 7px center;
          color: $color-white;
        }
      }
    }
  }
}

.checkout__header-item-count {
  display: none;
  text-align: right;
  width: 23%;
  .checkout__sidebar & {
    font-size: 14px;
    display: block;
    .item-count-number,
    .item-count-language {
      line-height: 50px;
    }
  }
}

// cart items header
.cart-header {
  font-family: $main-font;
  text-transform: uppercase;
  font-size: 14px;
  padding: 10px 0;
  //border-top: $cart-line;
  border-bottom: $cart-line;
  width: 100%;
  //margin-top: $cart-spacing;
  @include mobile_hidden;
  @include breakpoint($small-down) {
    display: none;
  }
  &__item {
    float: left;
  }
  &-sub-container {
    float: left;
    width: $cart-item-container-width;
  }
  &-spacer {
    width: $cart-item-desc-width;
  }
  .products {
    width: $cart-item-product-width;
    padding-left: $checkout-spacing;
  }
  .price {
    width: $cart-item-price-width;
  }
  .qty {
    width: $cart-item-qty-width;
  }
  .total {
    width: $cart-item-total-width;
    text-align: right;
  }
  &remove-spacer {
    width: $cart-item-remove-width;
  }

  // sidebar
  .checkout__sidebar & {
    display: none;
  }
}

.viewcart-banners-panel {
  margin-top: 30px;
  .banner {
    li {
      padding-right: 3.33%;
      float: left;
      width: 34.5%;
      &:last-child {
        padding-right: 0;
        width: 31%;
      }
      @include breakpoint($small-down) {
        display: block;
        margin-bottom: $checkout-spacing;
        width: 100%;
        .mobile_hidden {
          display: block;
          width: 100%;
        }
        &:last-child {
          width: 100%;
        }
      }
    }
  }
}
