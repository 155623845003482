// cart items
@mixin remove-link {
  @include link($color-pink, $color-pink, $touch: false);
  text-transform: uppercase;
  font-family: $main-font;
  font-size: 12px;
  font-weight: 500;
}

// cart sidebar styles
@mixin checkout-sidebar-cart-item {
  display: block;
  // position: absolute;
  // right: 0;
  width: 30%;
  text-align: right;
}

// CART ITEM
// content class: .checkout__content
// sidebar class: .checkout__sidebar

.cart-item {
  @include pie-clearfix;
  width: 100%;
  position: relative;
  padding: 10px 0 20px 0;
  border-bottom: $cart-line;
  &:last-child {
    border-bottom: none;
  }
  // content
  .checkout__content & {
  }
  // sidebar
  .checkout__sidebar & {
    position: relative;
    padding: 20px 0;
  }

  // product image
  &__thumb {
    float: left;
    text-align: center;
    &-link {
      display: block;
    }
    &-image {
      display: block;
      font-size: 8px;
      width: 100%;
      .checkout__content & {
        @include breakpoint($medium-up) {
          padding: 0 $cart-item-image-padding;
        }
      }
    }
    .checkout__content & {
      width: 38%;
      padding-right: 20px;
      @include breakpoint($medium-up) {
        width: $cart-item-product-width;
        padding-right: 0;
      }
    }
    .checkout__sidebar & {
      padding-right: 4%;
    }
  }

  // container for description/qty/price/total
  &__desc-container {
    @include pie-clearfix;
    float: left;
    .checkout__content & {
      width: 62%;
      @include breakpoint($medium-up) {
        width: $cart-item-container-width;
      }
    }
    .checkout__sidebar & {
      width: 72%;
    }
  }

  // description
  &__desc {
    line-height: 1.5; // let things breathe a bit
    .checkout__content & {
      @include breakpoint($medium-up) {
        float: left;
        width: $cart-item-desc-width;
      }
    }
    // sidebar
    .checkout__sidebar & {
      float: left;
      width: 70%;
    }
    .remove-sample {
      @include remove-link;
      margin-top: 10px;
      .checkout__sidebar & {
        display: none;
      }
    }
    // desc-info
    &-info {
      font-size: 12px;
      .color {
        margin: 14px 0 14px 0;
        @include breakpoint($medium-up) {
          margin: 14px 0 0 0;
        }
        .swatch {
          float: left;
        }
      }
      .shade {
        font-size: 14px;
        line-height: 22px;
        margin-left: 2.4em;
      }
      .shade-label {
        display: none;
      }
      // sidebar
      .checkout__sidebar & {
        .sub_line {
          display: none;
        }
      }
    }
  } // /desc

  // product name (desc)
  &__product-name {
    font-family: $main-font;
    font-weight: 500;
    text-transform: uppercase;
    a {
      @include link-reset;
      font-size: 11px;
      letter-spacing: 0.2em;
    }
  }

  // subname (desc)
  &__product-subname {
    @include hidden;
    // a {
    //   @include link-reset;
    //   font-size: 11px;
    //   letter-spacing: 0.2em;
    // }
  }

  // size (desc)
  &__size {
    font-size: 14px;
    margin-top: 3px;
    display: inline-block;
    @include breakpoint($medium-down) {
      color: $color-gray;
      font-size: 10px;
    }
  }

  // color (desc)
  &__color {
    display: block;
    margin-bottom: 5px;
    margin-top: 5px;
    @include breakpoint($medium-up) {
      margin-top: 10px;
    }
    .cart-item__swatch {
      border-radius: 11px;
      height: 20px;
      width: 20px;
      display: inline-block;
      vertical-align: middle;
    }
  }

  // remove column
  &__remove_column {
    @include breakpoint($medium-down) {
      float: left;
      margin: 0px;
      padding: 0px;
      text-align: left;
    }
  }
  // remove form (desc)
  &__remove-form {
    a {
      // remove_link?
      //@include remove-link;
      margin-top: 8px;
      padding-left: 23px;
      display: inline-block;
      @include breakpoint($medium-down) {
        margin-top: 5px;
        padding-left: 0;
      }
      @include breakpoint($small-down) {
        margin-top: 20px;
      }
    }
    &.loading {
      background-size: auto 100%;
    }
  }

  // qty
  &__qty {
    float: left;
    line-height: $checkout-price-column;
    text-align: center;
    .checkout__content & {
      @include breakpoint($small-down) {
        @include clearfix;
        clear: left;
        margin-top: 5px;
        padding-top: 10px;
      }
      @include breakpoint($medium-up) {
        padding-top: 0;
        width: $cart-item-qty-width;
      }
      @include breakpoint($small-down) {
        margin-top: 30px;
      }
    }
    // sidebar
    .checkout__sidebar & {
      @include checkout-sidebar-cart-item;
      margin: 0;
      padding: 20px 0 0 0;
      clear: none;
      float: right;
      text-transform: uppercase;
    }
    .update_btn {
      display: none;
    }
    select,
    a.selectBox,
    .selectbox & {
      display: block;
      text-align: $ldirection;
      @include breakpoint($small-down) {
        min-width: 64px; // override .touch default
        width: 64px;
      }
    }
    select {
      .touch & {
        min-width: 64px;
        width: 64px;
      }
    }
    form {
      width: 64px;
      @include breakpoint($small-down) {
        display: block;
        float: left;
      }
    }
    // __qty-label
    &-label {
      .checkout__content & {
        @include breakpoint($small-down) {
          float: left;
          line-height: 30px;
          margin-right: 7px;
        }
        @include breakpoint($medium-up) {
          display: none;
          padding-top: 5px;
          margin-top: 34px;
        }
      }
      .sample & {
        display: inline-block;
        @include breakpoint($medium-up) {
          display: none;
        }
      }
      .checkout__sidebar & {
        padding: 0;
        margin: 0;
        display: inline-block;
      }
    }
  } // /qty

  &__error {
    &--limited-qty {
      text-align: left;
      color: $color-red;
    }
  }

  // price
  &__price {
    line-height: $checkout-price-column;
    @include word-break(break-word);
    @include breakpoint($medium-up) {
      width: $cart-item-price-width;
      float: left;
      padding-right: 7%;
      text-align: right;
      word-break: normal;
    }
    // sidebar
    .checkout__sidebar & {
      display: none;
    }
    // Reposition for price being replaced with "Complimentary sample"
    // .checkout__sidebar .sample & {
    //   top: 0;
    // }
    &-label {
      display: none;
      .checkout__sidebar & {
        @include breakpoint($medium-up) {
          display: inline-block;
        }
      }
    }
    &--mobile-only {
      @include hidden;
    }
    .old_price {
      text-decoration: line-through;
    }
    .discount_price {
      display: block;
    }
  } // /price

  // total
  &__total {
    // content
    .checkout__content & {
      float: left;
      @include breakpoint($small-down) {
        padding-top: 5px;
      }
      @include breakpoint($medium-down) {
        line-height: 30px;
        margin-right: 10px;
        padding-top: 0;
      }
      @include breakpoint($medium-up) {
        padding-right: 0;
        line-height: $checkout-price-column;
        text-align: right;
        width: $cart-item-total-width;
      }
    }
    // sidebar
    .checkout__sidebar & {
      @include checkout-sidebar-cart-item;
      position: absolute;
      right: 0;
      top: 20px;
    }
  } // /total

  // giftcards in cart
  &__gc-to {
    margin-top: 3px;
  }
  &__gc-to,
  &__gc-from,
  &__gc-email,
  &__gc-message {
    font-family: $main-font;
    font-weight: 400;
    line-height: normal;
    text-transform: none;
  }
  &__gc-text {
    font-family: $main-font;
    text-transform: none;
  }
  &__gc-options {
    margin-top: 3px;
  }
} // /cart-item

a.cart-item__gc-edit,
a.cart-item__gc-remove {
  @include link($color-pink, $color-pink, $touch: false);
  font-size: 10px;
  padding-bottom: 3px;
}

a.cart-item__gc-buy {
  display: none;
}

// Replenishment
.cart-item__replenishment {
  padding: 20px 0 $cart-spacing 0;
  position: relative;
  clear: left;
  width: auto;
  @include breakpoint($medium-up) {
    width: 80%;
  }
  .checkout__sidebar & {
    padding: 0;
  }
  .selectbox {
    width: 100%;
    @include breakpoint($medium-up) {
      width: 195px;
    }
  }
}

.replenishment__description {
  //position: relative;
  font-family: $main-font;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  @include breakpoint($medium-up) {
    @include clearfix;
  }
  &--text {
    @include breakpoint($medium-up) {
      font-size: 14px;
      float: left;
      margin-right: 45px;
    }
  }
  &--link {
    display: none; // tooltip for pc, not shown for mobile
  }
}

.replenishment__form-container {
  margin-top: 8px;
}

.replen_notice {
  display: inline-block;
  padding: 0;
  margin-top: 15px;
  vertical-align: top;
  text-transform: none;
  @include breakpoint($small-down) {
    width: auto;
  }
}

.replenishment__description-text {
  @include hidden;
}

.replenishment__description-link {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  text-decoration: underline;
}

///
/// eGift cards
///
.gift-card {
  font-style: italic;
}

.gift-card__link {
  font-weight: bold;
  text-decoration: underline;
  font-style: normal;
}

// Errors (show after adding some samples)
.error {
  .cart-item & {
    clear: left;
    overflow: hidden; // clear
    margin-top: 25px; // extra space
  }
}
