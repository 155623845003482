/// Promo panel top
.promo-panel {
  margin-bottom: 20px;

  // display: none; // originally hid for mobile, now shown
  @include breakpoint($medium-up) {
    // show above mobile
    display: block;
  }
  img {
    height: 100px; // take out for live
    width: 100%;
    border: $cart-line;
  }
  .banner {
    padding: 17px;
    border-top: 2px solid black;
    background: $color-gray-background;
    strong,
    b {
      font-family: $main-font;
      font-weight: 500;
    }
  }
}

// offers
.offer-code-panel {
  border: 1px solid $color-gray-border;
  margin: 16px 0 20px;
  .checkout__content & {
    background: $color-gray-background;
    border: 1px solid $color-gray-border;
    padding: 16px 10px;
    input[type='submit'].btn {
      margin-top: 5px !important;
    }
  }
  .checkout__sidebar & {
    @include checkout-sidebar-panel;
    // extra padding up above to fit inline with with cart top because designers, only on full cart page
    .viewcart & {
      @include breakpoint($medium-up) {
        //margin-top: 148px;
      }
    }
    @include breakpoint($small-up) {
      margin: 16px 10px 20px;
    }
    @include breakpoint($medium-up) {
      margin: 16px 0 20px;
    }
    form {
      //position: relative;
      width: 100%;
    }
    input[type='submit'].btn {
      margin-top: 5px !important;
      padding-right: 25px !important;
      float: right;
    }
    .content {
      @include clearfix;
    }
  } // sidebar specific

  .enter_offer_code {
    text-transform: uppercase;
    margin-bottom: 10px;
    span.note {
      color: $color-gray-dark;
      text-transform: none;
    }
  }
  &__title {
    font-size: 20px;
    border-bottom: none;
    font-family: $main-font;
    font-weight: 400;
    letter-spacing: 0.1em;
    line-height: 24px;
    padding-bottom: 9px;
  }
  .offer-code-panel__content {
    margin-top: 20px;
  }
  .offer-code__byline {
    display: none;
  }
  .offer-code__one-offer-only {
    font-style: normal;
    margin: 18px 0 14px 0;
    display: block;
  }
  input[type='text'] {
    width: 100%;
    margin: 0;
    text-transform: capitalize;
  }
  input[type='submit'].btn {
    .checkout & {
      @include button;
      background-color: $color-black;
      margin: 0;
      height: 31px;
      line-height: 31px;
      padding: 0 10px;
      &.btn {
        @include button;
      }
      @include breakpoint($small-down) {
        width: 100%;
      }
    }
  }
}

// Newsletter, email, sms, promotions
.email-and-sms-promotions {
  @include clearfix;
  width: 100%;
  margin-top: $checkout-spacing;
  @include breakpoint($medium-up) {
    margin: $checkout-spacing 0;
    border-bottom: 1px solid $color-gray-light;
  }
  .overlay-link {
    display: block;
  }
  .pc_email_promo_container {
    margin-bottom: 18px;
  }
  .sms-mobile {
    display: none; //exposed by js
  }
  .sms-mobile-carrier,
  .sms-terms-and-conditions {
    display: none;
  }
}

.email-and-sms-promotions__subtitle {
  margin-bottom: 18px;
}

.email-and-sms-promotions__info-text {
  display: none;
}

//BCA Panel
.promo-bca-panel {
  display: none;
  margin-top: 5px;
  p {
    margin: 0;
  }
  &__title {
    font-weight: bold;
  }
  .checkout__sidebar & {
    display: block;
  }
  .promo_bca_content {
    &__right {
      float: right;
      width: 49%;
    }
    &__logo {
      width: 100%;
    }
  }
  .promo_bca_logo {
    float: right;
    width: 80%;
    left: 40%;
    position: absolute;
  }
  .promo_bca_description {
    float: left;
    display: block;
    width: 51%;
  }
  .promo_bca_link {
    color: $color-black;
  }
  .donation_amounts {
    display: inline;
  }
  .donation_amount_label {
    text-align: right;
    display: block;
    float: left;
    padding-right: 5px;
  }
  input[type='submit'].promo_bca_button {
    @include button;
    background-color: $color-black;
    margin: 16px 0 0 0;
    height: 31px;
    line-height: 31px;
    padding: 0 10px;
  }
  .promo_bca_note {
    display: none;
  }
  .onepage & {
    margin: 5px 20px 0;
    @include breakpoint($medium-up) {
      margin: 5px 0 0;
    }
  }
}
