// code: adplfeature_v1 (used to grep a site for portions of code associated with a given feature)
// forces all inputs, textareas and labels to use border-box sizing
input,
textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

label {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.panel label[data-required]:before {
  content: '';
}

// Adaptive placeholder defaults
// ------------------------------
// PRIVATE - default settings for adaptive placeholder

// Default User Placeholder Settings
// ------------------------------
// - Allows $ap-default to be overwritten by default user settings

$adaptive-placeholder: ();
