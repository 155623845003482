.review-panel {
  @include clearfix;
  @include checkout-content-panel;
  border-bottom: 1px solid $color-gray-border-section;
  &__title {
    @include checkout-section-header;
    margin-bottom: 20px;
  }
  .messages,
  .content {
    padding: 0 20px;
  }
  .pc-place-order,
  .email-and-sms-promotions,
  .section-sms-promotions,
  .msg-order-email {
    @include hidden;
  }
  &.collapse {
    border-bottom: none;
    .review-panel__title {
      margin-bottom: 0;
    }
  }
  .review-edit-content {
    .field-are-required {
      margin-bottom: 10px;
    }
    #checkout_saved_payment,
    .payment_method-container,
    .hidden_nojs {
      margin: 15px 0;
      p {
        margin-bottom: 10px;
      }
    }
    .expires-date {
      &__month,
      &__year {
        margin-bottom: 10px;
      }
    }
    .payment-type {
      margin-bottom: 10px;
      .related-media {
        margin-left: 20px;
        img {
          margin-right: 15px;
        }
      }
    }
    .continue-button-wrapper {
      margin: 20px 0;
      @include clearfix;
      input.place-order {
        float: right;
      }
    }
    .form-container {
      .address-to-use,
      .title {
        margin-bottom: 20px;
      }
      .form-item {
        margin-bottom: 10px;
        .card_number_field {
          margin-bottom: 0;
        }
      }
      .first-name {
        width: 49%;
        float: left;
        margin-right: 2%;
      }
      .last-name {
        width: 49%;
        float: left;
      }
      .set-address,
      .view-address {
        margin-top: 20px;
      }
    }
    .payment_method-container {
      .form-container {
        width: 45%;
      }
      #checkout_payment {
        .form-item.card-type {
          width: 65%;
        }
        .form-item.card-number input {
          height: 32px;
          line-height: 20px;
          padding: 5px 10px;
        }
        @include breakpoint($medium-up) {
          .form-item.card-number {
            width: 253px;
          }
          .form-item.card-expiry-date {
            .expires-date__month,
            .expires-date__year {
              float: left;
              width: 106px;
            }
            .expires-date__year {
              margin-left: 41px;
            }
          }
        }
        .form-item.card-cvn {
          label {
            display: block;
          }
          input {
            float: left;
            height: 32px;
            line-height: 20px;
            margin-right: 20px;
            padding: 5px 10px;
            width: 30%;
          }
          #security-code {
            display: block;
            height: 32px;
            line-height: 20px;
            padding: 5px 0;
          }
        }
      }
    }
    @include breakpoint($small-down) {
      #checkout_billing_panel {
        .payment-type {
          .payment-option {
            display: block;
          }
        }
        .sub-section {
          .card-method-option {
            display: block;
          }
        }
      }
      .sub-section {
        margin-bottom: 30px;
        .payment-address {
          margin-top: 10px;
        }
        .set-address {
          .address-to-use-option {
            display: block;
          }
          .new-address {
            .form-item input {
              margin-bottom: 0;
            }
          }
        }
      }
      #checkout_review {
        .divide {
          margin: 15px 0;
        }
      }
    }
  }
}
