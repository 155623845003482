.checkout {
  input[type='text'],
  input[type='password'],
  input[type='textfield'],
  input[type='tel'],
  input[type='number'],
  textarea,
  select {
    width: 100%;
    &:disabled {
      background-color: $color-gray-light;
    }
  }
}
